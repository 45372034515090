#cookie-consent .container {
  width: 100%;
}

@media (min-width: 640px) {
  #cookie-consent .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  #cookie-consent .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  #cookie-consent .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  #cookie-consent .container {
    max-width: 1280px;
  }
}

#cookie-consent .bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

#cookie-consent .bg-primary-500 {
  --bg-opacity: 1;
  background-color: #2B6CB0;
  background-color: rgba(43, 108, 176, var(--bg-opacity));
}

#cookie-consent .bg-neutral-100 {
  --bg-opacity: 1;
  background-color: #FAFAFA;
  background-color: rgba(250, 250, 250, var(--bg-opacity));
}

#cookie-consent .bg-neutral-300 {
  --bg-opacity: 1;
  background-color: #E9E9E9;
  background-color: rgba(233, 233, 233, var(--bg-opacity));
}

#cookie-consent .bg-neutral-500 {
  --bg-opacity: 1;
  background-color: #C8C8C8;
  background-color: rgba(200, 200, 200, var(--bg-opacity));
}

#cookie-consent .bg-neutral-800 {
  --bg-opacity: 1;
  background-color: #5A5A5A;
  background-color: rgba(90, 90, 90, var(--bg-opacity));
}

#cookie-consent .hover\:bg-primary-600:hover {
  --bg-opacity: 1;
  background-color: #27619E;
  background-color: rgba(39, 97, 158, var(--bg-opacity));
}

#cookie-consent .hover\:bg-neutral-600:hover {
  --bg-opacity: 1;
  background-color: #B4B4B4;
  background-color: rgba(180, 180, 180, var(--bg-opacity));
}

#cookie-consent .bg-opacity-75 {
  --bg-opacity: 0.75;
}

#cookie-consent .border-neutral-300 {
  --border-opacity: 1;
  border-color: #E9E9E9;
  border-color: rgba(233, 233, 233, var(--border-opacity));
}

#cookie-consent .border-neutral-500 {
  --border-opacity: 1;
  border-color: #C8C8C8;
  border-color: rgba(200, 200, 200, var(--border-opacity));
}

#cookie-consent .rounded-sm {
  border-radius: 0.125rem;
}

#cookie-consent .rounded {
  border-radius: 0.25rem;
}

#cookie-consent .border-solid {
  border-style: solid;
}

#cookie-consent .border-0 {
  border-width: 0;
}

#cookie-consent .border {
  border-width: 1px;
}

#cookie-consent .border-r-0 {
  border-right-width: 0;
}

#cookie-consent .border-b {
  border-bottom-width: 1px;
}

#cookie-consent .cursor-pointer {
  cursor: pointer;
}

#cookie-consent .flex {
  display: flex;
}

#cookie-consent .hidden {
  display: none;
}

#cookie-consent .flex-col {
  flex-direction: column;
}

#cookie-consent .flex-wrap {
  flex-wrap: wrap;
}

#cookie-consent .items-start {
  align-items: flex-start;
}

#cookie-consent .items-center {
  align-items: center;
}

#cookie-consent .justify-start {
  justify-content: flex-start;
}

#cookie-consent .justify-between {
  justify-content: space-between;
}

#cookie-consent .font-body {
  font-family: "Helvetica Neue", Arial, "Noto Sans", sans-serif;
}

#cookie-consent .font-semibold {
  font-weight: 600;
}

#cookie-consent .font-bold {
  font-weight: 700;
}

#cookie-consent .h-full {
  height: 100%;
}

#cookie-consent .text-xs {
  font-size: 0.75rem;
}

#cookie-consent .text-sm {
  font-size: 0.875rem;
}

#cookie-consent .text-base {
  font-size: 1rem;
}

#cookie-consent .text-lg {
  font-size: 1.125rem;
}

#cookie-consent .m-2 {
  margin: 0.5rem;
}

#cookie-consent .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

#cookie-consent .mb-2 {
  margin-bottom: 0.5rem;
}

#cookie-consent .ml-2 {
  margin-left: 0.5rem;
}

#cookie-consent .-ml-2 {
  margin-left: -0.5rem;
}

#cookie-consent .max-w-xl {
  max-width: 36rem;
}

#cookie-consent .max-w-4xl {
  max-width: 56rem;
}

#cookie-consent .opacity-0 {
  opacity: 0;
}

#cookie-consent .opacity-100 {
  opacity: 1;
}

#cookie-consent .overflow-auto {
  overflow: auto;
}

#cookie-consent .p-2 {
  padding: 0.5rem;
}

#cookie-consent .p-3 {
  padding: 0.75rem;
}

#cookie-consent .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#cookie-consent .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

#cookie-consent .px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

#cookie-consent .py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

#cookie-consent .px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

#cookie-consent .pt-4 {
  padding-top: 1rem;
}

#cookie-consent .pointer-events-none {
  pointer-events: none;
}

#cookie-consent .fixed {
  position: fixed;
}

#cookie-consent .top-0 {
  top: 0;
}

#cookie-consent .right-0 {
  right: 0;
}

#cookie-consent .bottom-0 {
  bottom: 0;
}

#cookie-consent .left-0 {
  left: 0;
}

#cookie-consent .shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

#cookie-consent .text-center {
  text-align: center;
}

#cookie-consent .text-black {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

#cookie-consent .text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

#cookie-consent .text-neutral-800 {
  --text-opacity: 1;
  color: #5A5A5A;
  color: rgba(90, 90, 90, var(--text-opacity));
}

#cookie-consent .no-underline {
  text-decoration: none;
}

#cookie-consent .w-1\/3 {
  width: 33.333333%;
}

#cookie-consent .w-2\/3 {
  width: 66.666667%;
}

#cookie-consent .w-full {
  width: 100%;
}

#cookie-consent .transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

#cookie-consent .translate-y-0 {
  --transform-translate-y: 0;
}

#cookie-consent .translate-y-24 {
  --transform-translate-y: 6rem;
}

#cookie-consent .transition-all {
  transition-property: all;
}

#cookie-consent .ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

#cookie-consent .duration-500 {
  transition-duration: 500ms;
}

#cookie-consent p {
  margin: 0;
  padding: 0;
}

/* The switch - the box around the slider */

#cookie-consent .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */

#cookie-consent .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */

#cookie-consent .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

#cookie-consent .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

#cookie-consent input:checked + .slider {
  background-color: #2b6cb0;
}

#cookie-consent input:focus + .slider {
  box-shadow: 0 0 1px #2b6cb0;
}

#cookie-consent input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */

#cookie-consent .slider.round {
  border-radius: 34px;
}

#cookie-consent .slider.round:before {
  border-radius: 50%;
}

@media (min-width: 640px) {
  #cookie-consent .sm\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    #cookie-consent .sm\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    #cookie-consent .sm\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    #cookie-consent .sm\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    #cookie-consent .sm\:container {
      max-width: 1280px;
    }
  }

  #cookie-consent .sm\:m-4 {
    margin: 1rem;
  }

  #cookie-consent .sm\:p-3 {
    padding: 0.75rem;
  }

  #cookie-consent .sm\:p-6 {
    padding: 1.5rem;
  }

  #cookie-consent .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

@media (min-width: 768px) {
  #cookie-consent .md\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    #cookie-consent .md\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    #cookie-consent .md\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    #cookie-consent .md\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    #cookie-consent .md\:container {
      max-width: 1280px;
    }
  }

  #cookie-consent .md\:mt-5 {
    margin-top: 1.25rem;
  }
}

@media (min-width: 1024px) {
  #cookie-consent .lg\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    #cookie-consent .lg\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    #cookie-consent .lg\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    #cookie-consent .lg\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    #cookie-consent .lg\:container {
      max-width: 1280px;
    }
  }

  #cookie-consent .lg\:mt-10 {
    margin-top: 2.5rem;
  }
}

@media (min-width: 1280px) {
  #cookie-consent .xl\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    #cookie-consent .xl\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    #cookie-consent .xl\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    #cookie-consent .xl\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    #cookie-consent .xl\:container {
      max-width: 1280px;
    }
  }

  #cookie-consent .xl\:text-sm {
    font-size: 0.875rem;
  }

  #cookie-consent .xl\:text-xl {
    font-size: 1.25rem;
  }

  #cookie-consent .xl\:mt-20 {
    margin-top: 5rem;
  }
}

